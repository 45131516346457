/* eslint-disable consistent-return */
import { bootstrap, ContentBlockRenderer } from '@plugins/next-cms-core';
import DefaultTemplate from '@components/templates/DefaultTemplate';
import Error from 'next/error';
import Head from 'next/head';
import PageInsetTop from '@components/atoms/PageInsetTop';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useHideConsentOnPrivacyPage } from '@lib/gdpr';
import Banner from '@components/molecules/Banner';
import { Box } from '@mui/material';
import PromotionExpired from '@components/molecules/PromotionExpired';
import { getAppData } from '@lib/appInitialState';
import { getPathFromQuery } from '@lib/utils/path';
import { components } from '@/cmsconfig';

/**
 * This is the entry point for the CMS application. It takes an id
 * and fetches the data from the database for the target Page.
 *
 */
// eslint-disable-next-line no-unused-vars
export default function CmdCoreAppIndex({
  appData,
  page,
}) {
  useHideConsentOnPrivacyPage([
    '/datenschutz',
    '/privacy-policy',
    '/proteccion-de-datos',
    '/zashhita-dannyh',
    '/veri-koruma',
    '/impressum',
    '/imprint',
    '/pie-de-imprenta',
    '/ottisk',
    '/kuenye',
  ]);

  if (!page) {
    return (
      <Error statusCode={404} title="Inhalt nicht gefunden" />
    );
  }

  if (!page.publishedAt) {
    return (
      <Error statusCode={410} title="Inhalt ist nicht mehr vorhanden" />
    );
  }

  const {
    robots,
    metaPageTitle,
    metaPageDescription,
    isTopContentOffset,
    expirationDate,
    isPromotionExtended,
  } = page;
  const componentData = {
    id: page.id,
    blocks: page.content ?? [],
  };

  let isBannerRendered = false;
  const pageTitle = `${metaPageTitle ?? ''}`;

  return (
    <>
      <Head>
        <title>
          {pageTitle}
        </title>
        <meta content={metaPageDescription} name="description" />
        {robots && robots !== 'index' && (
          <meta content={robots} name="robots" />
        )}
        <meta content={pageTitle} property="og:title" />
      </Head>
      <DefaultTemplate>
        {isTopContentOffset && <PageInsetTop />}
        <PromotionExpired
          expirationDate={expirationDate}
          isPromotionExtended={isPromotionExtended}
        />
        <ContentBlockRenderer
          appData={appData}
          components={components}
          data={componentData}
          context={{ page }}
          // eslint-disable-next-line react/no-unstable-nested-components
          preComponentRender={(component) => {
            // eslint-disable-next-line no-underscore-dangle
            if (!isBannerRendered && !PRE_RENDER_SKIP_COMPONENTS.includes(component.typeName)) {
              isBannerRendered = true;

              return (
                <Box my={3}>
                  <Banner />
                </Box>
              );
            }
          }}
        />
      </DefaultTemplate>
    </>
  );
}

CmdCoreAppIndex.propTypes = {
  page: PropTypes.object,
};

export async function getServerSideProps(ctx) {
  const {
    query,
    res,
    locale,
  } = ctx;
  const [graphQlClient, appData] = await getAppData(ctx);
  const path = getPathFromQuery(query);

  const {
    data,
    error,
  } = await graphQlClient.query({
    query: FETCH_PAGE,
    variables: {
      path,
      locale,
      localeStr: locale,
    },
  });

  if (error) {
    throw error;
  }

  if (!data.resolvePageByPath && res) {
    res.statusCode = 404;
  } else if (!data.resolvePageByPath?.publishedAt && res) {
    res.statusCode = 410;
  }

  if (res && data.cacheState.isEnabled) {
    res.setHeader('Cache-Control', 'public, max-age=3600');
  }

  const statusCode = res?.statusCode ?? 200;

  return {
    props: {
      ...appData,
      statusCode,
      path: statusCode === 200 ? path : '/',
      page: data.resolvePageByPath,
      pageBottomConfig: {
        bottomInfoText: data.resolvePageByPath?.bottomInfoText ?? null,
      },
      isGlobalNoticeHidden: Boolean(data.resolvePageByPath?.isGlobalNoticeHidden),
      canonicalUrl: data.resolvePageByPath?.metaCanonicalUrl ?? data.resolvePageByPath?.url ?? null,
    },
  };
}

const { graphQlFragments } = bootstrap({
  components,
});

const PRE_RENDER_SKIP_COMPONENTS = [
  'ComponentContentPageHeader',
  'ComponentContentCarSearchFilter',
  'ComponentContentModelRequestForm',
];

const FETCH_PAGE = gql`
  query ResolvePageByPath($path: String!, $locale: I18NLocaleCode!, $localeStr: String!) {
    resolvePageByPath(path: $path, locale: $locale) {
      name
      publishedAt
      publishDate
      metaPageTitle
      expirationDate
      bottomInfoText
      isTopContentOffset
      isPromotionExtended
      isGlobalNoticeHidden
      metaPageDescription
      metaCanonicalUrl
      url
      robots
      content {
        ${graphQlFragments}
      }
    }
    cacheState {
      isEnabled
    }
  }
`;
